import axios from 'axios';
import _ from 'lodash';
import cacheFile from './PdfCache';

const Guides = {
  async refresh() {
    if (typeof localStorage === 'undefined') {
      this.supported = false;
    }

    await axios.get(`${process.env.VUE_APP_API_BASE}/get/`)
      .then((response) => {
        // handle success
        const guides = JSON.stringify(response.data);
        localStorage.setItem('guides', guides);
        let timestamp = JSON.stringify(response.data.timestamp);
        timestamp = timestamp.replace(/['"]+/g, '');
        localStorage.setItem('timestamp', timestamp);
        localStorage.setItem('refreshGuides', true);

        response.data.data.forEach((guide) => {
          cacheFile(guide.file_url.url);
        });
      })
      .catch(() => {
        // handle error
        localStorage.setItem('refreshGuides', false);
      });

    return new Promise((resolve) => {
      resolve();
    });
  },
  get_guides() {
    const guides = JSON.parse(localStorage.getItem('guides')).data;
    return this.language(localStorage.getItem('lang'), guides);
  },
  get_categories() {
    const guides = this.get_guides();
    const categories = [];

    const lang = localStorage.getItem('lang');
    categories.push(lang === 'en' ? 'All' : 'Holl');

    guides.forEach((guide) => {
      if (typeof guide.terms === 'object') {
        guide.terms.forEach((term) => {
          categories.push(term.name);
        });
      }
    });

    const uniqueSet = new Set(categories);
    return [...uniqueSet];
  },
  filter_guides(term) {
    const guides = this.get_guides();
    const filtered = [];

    if (term === 'All' || term === 'Holl') {
      return guides;
    }

    guides.forEach((guide) => {
      if (guide.terms) {
        const search = _.find(guide.terms, (item) => item.name === term);
        if (search) {
          filtered.push(guide);
        }
      }
    });
    return filtered;
  },
  guide(id) {
    return _.find(this.get_guides(), (guide) => parseInt(id, 10) === guide.ID);
  },
  language(lang, guides) {
    return _.filter(guides, (guide) => guide.language === lang);
  },
};

export default Guides;
