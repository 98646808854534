<template>
  <Navigation v-if="!['Language'].includes($route.name)" :show="$route.name !== 'PDF'" />
  <div v-if="supported">
    <InstallPwa></InstallPwa>
    <AppleInstallPwa></AppleInstallPwa>
    <div class="debug-bar"></div>
    <router-view/>
  </div>
  <div v-else>
    <UnsupportedBrowser></UnsupportedBrowser>
  </div>
</template>

<script>

import Navigation from '@/components/Navigation.vue';
import UnsupportedBrowser from '@/components/UnsupportedBrowser.vue';
import InstallPwa from './components/InstallPwa.vue';
import AppleInstallPwa from './components/AppleInstallPwa.vue';

export default {
  name: 'App',
  components: {
    Navigation,
    InstallPwa,
    AppleInstallPwa,
    UnsupportedBrowser,
  },
  data() {
    return {
      supported: true,
    };
  },
  async created() {
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', 'en');
    }
  },
};

</script>

<style lang="scss">
.main {
  margin-top: 1rem;
}
</style>
