<template>
  <div class="wrapLang">
      <img class="logo" alt="Vue logo" src="../../public/img/logos/logo-new-512.png">
    <div class="btnContainer">
      <LangButton  @click="setLanguage('cy')">Cymraeg</LangButton>
      <LangButton  @click="setLanguage('en')">English</LangButton>
    </div>
  </div>
</template>

<script>

import LangButton from '../components/partials/LangButton.vue';

export default {
  name: 'Language',
  components: {
    LangButton,
  },
  methods: {
    setLanguage(lang) {
      localStorage.setItem('lang', lang);
    },
  },
};
</script>

<style scoped lang="scss">
.wrapLang {
  background: #fff;
  min-height: 100vh;
  min-width: 100%;
  margin: 0;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .logo {
    margin: 0 auto;
    width: 15rem;

    @media (min-width: $lg) {
      width: 20rem;
    }
  }

  .btnContainer {
    padding: 1rem;
    text-align: center;
  }
}
</style>
