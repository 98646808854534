<template>
  <div class="home main">
    <TitleText>{{ $t('home.titleText') }}</TitleText>
    <div class="container">
      <div class="filter-wrap">
        <DropdownFilter @select="filter_guides"></DropdownFilter>
      </div>
      <div class="flex-grid">
          <Card
            v-for="guide in guides"
            v-bind:key="guide.ID"
            v-bind:title="guide.post_title"
            v-bind:category="guide.terms"
            v-bind:image="guide.thumbnail"
            v-bind:id="guide.ID"></Card>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleText from '@/components/TitleText.vue';
import DropdownFilter from '@/components/DropdownFilter.vue';
import Card from '@/components/Card.vue';
import Guides from '@/js/Guides';

export default {
  name: 'Home',
  components: {
    TitleText,
    DropdownFilter,
    Card,
  },
  data() {
    return {
      guides: [],
      filter: false,
    };
  },
  mounted() {
    this.guides = Guides.get_guides();
  },
  methods: {
    filter_guides(el) {
      this.filter = el;

      this.guides = Guides.filter_guides(el);
    },
  },
};
</script>

<style scoped lang="scss">
.home {
  margin-bottom: 2rem;

  .filter-wrap {
    margin-bottom: 2.5rem;
  }

  .result-wrap {
    margin-bottom: 1rem;
  }

  .flex-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: end;
  }
}
</style>
